.footerupper {
  /* background-color: black; */
  background-image: url(./../images/26539.jpg);
  background-repeat: no-repeat;
  background-size: 100% 130%;
  /* background-size: cover; */
  margin:0;
  padding: 0.5rem 0rem 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerupperinnerdiv{
  display: flex;
  width: 90%;
}

.lowerring{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  width: 32%;
  text-justify: justify;
}
.footerlogo{
  width: 100px;
  height: 100px;
}
.footerlower{
  background-color: #ffd301;
  padding: 10px 2px 10px 2px;
}
.footerIcon{
  margin-left: 80px;
}

.footerIcon ul {
  list-style-type: none;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem 2rem 0rem;
}
.footerIcon ul li {
  text-decoration: none;
  margin: 0.5rem;
}
.footerlower {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.footerlower a {
  color: black;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.footerIcon ul li a img {
  width: 45px;
}

@media only screen and (max-width: 600px) {
  .footerIcon ul li a img {
    width: 45px;
  }
  .footerupper{
    width: 100%;
  }
  .footerlower {
    flex-direction: column;
    padding-left: 1rem;
    align-items: center; 
    /* justify-content: center; */ 
    padding-right: 1rem;
  }
  .lowerring{
    width: 100%;
  }
  .footerIcon{
    margin-left: 0px;
  }
  .footerupperinnerdiv{
    flex-direction: column;
  }
  .footerIcon ul {
    padding-left: 0;
  }
  .footerlower a {
    text-align: center;
}
}
