.checkoutHeading {
  margin-top: 40px;
  padding: 10px 50px;
}

.checkoutHeading h1 {
  padding: 50px 0 10px 0;
  font-size: 38px;
  color: #363434;
  font-family: "Montserrat", sans-serif !important;
  line-height: 37px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}

.checkoutrow {
  width: 100%;
  display: flex;
  padding: 10px 90px;
}

.checkoutcolumn {
  flex: 1;
  margin: 15px;
}

.checkoutcolumn-heading{
  padding: 10px 20px;
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #e6b745;
}

@media screen and (max-width: 600px) {
  .checkoutSection{
    padding: 0px;
  }

  .checkoutrow{
    padding: 0px;
    flex-direction: column;
  }

  .checkoutpageinput{
    margin: 10px;
  }

  .nameinput{
    width: 100%;
  }
}