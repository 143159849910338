.foodforlife {
  display: flex;
  /* margin: 2rem 2rem 2rem 1rem;*/
  padding: 1rem 0rem 1rem 2rem; 
}
.foodImage img {
  width: 27rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
}
/* .foodImage {
  border: 2px solid black;
  margin: 1rem;
  margin-right: 5rem;
  border-radius: 1.5rem;
} */
.para h1 {
  color: red;
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}
.para{
padding-left: 5rem;
}
.para p {
  line-height: 25px;
  text-align: justify;
  font-size: 20px;
  width: 90%;
}

@media screen and (max-width: 600px) {
  .foodforlife {
    display: flex;
    padding-top: 2rem !important;
    margin: 0rem;
    padding: 0rem;
    flex-direction: column;
    align-items: center;
  }
  .foodImage img {
    width: 17rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-radius: 1.5rem;
    -webkit-box-shadow: -1px 0px 17px 16px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: -1px 0px 17px 16px rgba(0, 0, 0, 0.51);
    box-shadow: -1px 0px 17px 16px rgba(0, 0, 0, 0.51);
    
  }
  .foodImage{
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .para p {
    line-height: 25px;
    text-align: justify;
    font-size: 20px;
    width: 100%;
    padding: 1rem;
  }
  .para h1 {
    color: gold;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0rem;
    padding-top: 1.7rem;
    text-align: center;
  }
  /* .foodImage {
    border: 2px solid black;
    margin: 1rem;
    margin-right: 3rem;
    margin-left: 3rem;
    border-radius: 1.5rem;
  } */
  .para{
    padding-left: 0rem;
    }
}
