/* .upperHeader {
  display: flex;
  flex-direction: roww;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
}
header {
  
  width: 100%;
  position:-ms-page;
  overflow: hidden;
  
}
.sticky {
  background-image: url(../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #fff; Add your desired background color
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}



.hawan li a {
  text-decoration: none;
  font-size: 15px;
  margin: 0.5rem;
  color: white;
}
.hawan li {
  display: flex;
  align-items: center;
}
.hawan li img {
  width: 15px;
}
hr {
  border: 1px solid white;
  position: relative;
  top: 9px;
}
.headerLower ul li a {
  text-decoration: none;
  font-size: 15px;
  color: white;
  padding: 1rem;
  text-transform: uppercase;
}
.heading {
  padding: 3rem;
}
.heading h2 {
  text-transform: uppercase;
  color: orange;
  text-align: center;
}
.heading img {
  position: relative;
  left: 40%;
}
.heading h1 {
  text-transform: uppercase;
  color: white;
  text-align: center;
}
.socialLogos li a {
  text-decoration: none;
  font-size: 25px;
  margin: 0.5rem;
  color: white;
}
.liveDarshan li {
  margin: 0.5rem;
}
.liveDarshan li a {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}
.iconn .iconnn {
  background-color: white;
  float: none;
  display: inline-block !important;
  background-image: url(./../images/logobg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 5px 66px !important;
  height: 87px;
  min-width: 254px;
  text-align: center;
  z-index: 10;
  position: relative;
}
.iconn img {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  float: none;
  max-width: 70px;
}
ul {
  display: flex;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  align-items: flex-start;
}
.online {
  display: flex;
  align-items: baseline;
}

.blink_me {
  animation: blinker 1s linear infinite;
  color: orange;
  font-size: 15px;
}

.nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
}

.nav-btn svg {
  position: relative;
  top: -445px; 
   left: 349px;
  color: white;
  font-size: 29px;
}

@media only screen and (max-width: 600px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .nav-btn svg {
    position: relative;
    left: 26rem;
    bottom: 20rem;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
  }
  .responsive_nav {
    transform: none;
  }
  .socialLogos {
    display: none;
  }
  .hawan {
    display: none;
  }
  .responsive {
    display: none;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

 */

/* nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  position: fixed !important;
  z-index: 120;
  width: 100%;
  height: 15vh;
}

#navbarr {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbarr li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbarr li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  transition: 0.3s ease-in-out;
}

#navbarr li a:hover,
#navbarr li a.active {
  color: rgb(141, 9, 9);
}

#navbarr li a:hover::after,
#navbarr li a.active::after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: rgb(153, 30, 30);
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#mobile {
  display: none;
}

#mobile svg {
  color: rgb(153, 30, 30);
}

@media screen and (max-width: 769px) {
  nav{
    justify-content: space-around;
    padding: 10px 2px 10px 2px;
  }
  #navbarr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 120px;
    right: -300px;
    width: 252px;
    height: 100vh;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    z-index: 100;
    transition: 0.3s ease-in-out;
    /* border-radius: 15px 0px; */
/* } */

/* #navbarr.active{
    right: 0px;
  }

  #navbarr li{
    margin-bottom: 25px;
  }

  #mobile{
    display: block;
  }
  #mobile svg{
    font-size: 30px;
    cursor: pointer;
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

nav {
  position: sticky;
  z-index: 99;
  width: 100%;
  background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
}
nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 100px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: inline-flex;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  color: #231f40;
  text-decoration: none;
  font-size: 23px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.nav-links li a img{
  margin-right: 5px;
}
.nav-links li a:hover,
.nav-links li a.active {
  color: rgb(141, 9, 9);
}

.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 230px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
  padding-left: 3px;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 50%;
  width: 56%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background-image: url(./../images/background-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.mega-box .content .rowww {
  width: calc(49% - 0px);
  line-height: 28px;
  /* margin-left: 12px; */
}
.content .rowww img {
  /* width: 92%;
  height: 79%; */
  /* object-fit: cover; */
  border-radius: 60px;
  padding: 17px;
}
.content .rowww header {
  color: maroon;
  font-size: 20px;
  font-weight: 500;
  margin-left: 14px;
}
.content .rowww .mega-links {
  margin-left: -60px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.rowww .mega-links li {
  padding: 0 20px;
}
.rowww .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #000000;
  font-size: 17px;
  display: block;
  width: 120%;
  margin-bottom: -15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.rowww .mega-links li a:hover,
.rowww .mega-links li a.active{
  color: maroon;
}
.rowww img{
  width: 20rem;
}
.dharmikicon{
  width: 70px !important;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 600px)  {  
  .wrapper {
    justify-content: end;
  }
  .wrapper .btn {
    display: block;
  }
  .logo a img {
    width: 85px;
  }
  .rowww img{
    width: 13rem;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 26px;
    color: #231f40;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showDrop1:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box,
  #showMega1:checked ~ .mega-box,
  #showMega2:checked ~ .mega-box,
  #showMega3:checked ~ .mega-box,
  #showMega4:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #231f40;
    font-size: 26px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: maroon;
    width: 100%;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box {
    width: 100%;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .rowww {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .rowww:nth-child(1),
  .mega-box .content .rowww:nth-child(2) {
    border-top: 0px;
  }
  .content .rowww .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .rowww .mega-links li {
    margin: 0;
  }
  .content .rowwww header {
    font-size: 19px;
  }
  .rowww .mega-links li a {
    padding: 0px;
    padding: 0px;
    color: #000000;
    font-size: 13px;
    display: block;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .headermenu {
    width: fit-content;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
