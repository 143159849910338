.termsection{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.termsdiv{
    width: 80%;
    margin-top: 30px; 
}

.termsheading{
    font-weight: 800;
    font-size: 60px;
}

.h4heading{
    font-weight: 600;
}

@media screen and (max-width:600px) {
    .termsheading{
        font-size: 30px;
    }
}