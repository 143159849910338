.forgetpassdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45vh;
}

.forgetpass{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 25%;
}

.forgetpassheading{
margin-bottom: 20px;
font-weight: 700;
}

.forgetpassinput{
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.forgetpassbtn{
    margin: auto;
    border: none;
    background-color: #e6b745;
    padding: 12px;
    border-radius: 10px 10px 20px 10px;
    font-weight: 700;
}

@media screen and (max-width:600px) {
    .forgetpass{
        width: 100%;
        padding: 0px 20px;
    }
}