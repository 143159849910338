.reportdiv{
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
}
  .reportrow {
    width: 100%;
    display: flex;
    padding: 10px 90px;
  }
  
  .reportcolumn {
    flex: 1;
    margin: 15px;
  }
  
  .reportcolumn-heading{
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #e6b745;
  }

  .reportcolumncard{
    padding-left: 50px;
  }

  .pdfimage{
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 600px) {
    .reportdiv{
      padding: 0px;
    }
  
    .reportrow{
      padding: 0px;
      flex-direction: column;
    }

}