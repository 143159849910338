/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media screen and (max-width: 768px) {
  .contributionButton {
    right: 30px;
  }
}

.upper-header,
.lower-header {
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: center;
  width: 100%;
  z-index: 1000;
}

.upper-header.hidden {
  display: none;
}

.lower-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  animation: fadeInDown 0.3s ease-in-out;
}

@keyframes fadeInDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

main {
  margin-top: 100px; /* Set to the height of the upper header */
  padding: 20px;
  /* Add more styling for your main content as needed */
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
