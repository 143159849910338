@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
.upperheader3 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem 0rem 1rem 0rem;
}
.header3menu {
  /* border: 2px solid black; */
  border-radius: 3rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header3menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  padding-top: 10px;
  padding-bottom: 10px;
}

.header3menu ul li {
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.4s ease, color 0.4s ease;
  font-family: "Inter", sans-serif;
}

.header3menu ul li a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ffd301;
  opacity: 0;
  transition: width 1s ease, opacity 1s ease, transform 1s ease;
  z-index: -1;
  border-radius: 2rem;
}

.header3menu ul li a:hover,
.header3menu ul li a.active {
  background-color: orange;
  color: white;
  z-index: 1;
  border-radius: 2rem;
}

.header3menu ul li a:hover::after,
.header3menu ul li a.active::after {
  /* width: 100%; */
  opacity: 1;
  transform: translateX(5px);
  border-radius: 2rem;
}

.header3menu ul {
  list-style-type: none;
  display: flex;
  padding-left: 0rem;
  margin: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.header3menu ul li a {
  text-decoration: none;
  padding: 1rem;
  color: #000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 2rem;
}

.header3icon img {
  /* position: relative; */
  width: 3.2rem;
  border-radius: 100%;
  padding: 0.5rem;
}
.header3icon {
  fill: gold;
  background-color: #ffd301;
  border-radius: 100%;
  /* box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.25) inset; */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: background-color 2s ease-out 100ms;
  /* background: url(./../images/donateicon.png), lightgray 50% / cover no-repeat;  */
}

.header3icon:hover {
  background-color: orange;
  transition: 2s;
}

@media only screen and (max-width: 600px) {
  .header3menu ul {
    flex-direction: column;
  }
}
