.popupmenudiv {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 400px;
    width: 400px;
    z-index: 3;
  }
  
  .popupmenudiv img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #333;
    margin-bottom: 5px;
    position: absolute;
  }
  
  .closeButton:hover {
    color: #ff0000; 
  }
  
  @media screen and (max-width:600px) {
    .popupmenudiv{
      /* right: 27%; */
      width: 300px;
      height: 300px;
    }
  }