.downloadappsection{
    justify-content: center;
    align-items: center;
}
.uldiv {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd301;
    width: fit-content;
    margin: 20px auto;
    border-radius: 15px 0 15px 0;
}
  
.ult_tabmenu {
    display: flex;
    list-style: none;
    padding: 0;
}
  
.ult_tab_li {
    padding: 10px;
    margin-right: 10px;
    text-align: center;
}

.ult_tab_li:hover {
    background-color: orange;
}

.link{
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.monthlywalpaperdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.monthlywalpaperrow{
    width: 85%;
    display: flex;

}

.monthlywalpapercolumn {
    flex: 1;
    width: 30%;
    margin: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.monthlywalpapercolumn h2{
    font-weight: 600;
}
.wallpaperimage{
    height: auto;
    max-width: 100%;
    vertical-align: top;
}


@media screen and (max-width: 600px) {
    .uldiv{
        flex-direction: column;
    }

    .ult_tabmenu {
        flex-direction: column;
    }
    .monthlywalpaperrow{
        flex-direction: column;
    }
    .monthlywalpapercolumn{
        width: 100%;
    }
  }

