.topheadingDona {
  background-image: url(./../images/images3.webp);
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 20rem; */
}
p {
  font-size: 17px !important;
}
.topheadingDona h1 {
  text-align: center;
  padding: 1.7rem;
  color: red;
  font-size: 50px;
  font-weight: 700;
}
.nityasevacontri h1 {
  text-align: center;
  color: #ffd301;
  font-weight: 700;
  margin: 1rem;
}
.nityasevacontri p {
  text-align: center;
}
.nityasevapara {
  padding: 0rem 2rem 1rem 2rem;
}
.topheadingDona h2 {
  text-align: center;
  margin-bottom: -1.5rem !important;
  padding: 1rem 0rem 1rem 0rem;
  color: #ffd301;
  font-size: 40px;
  font-weight: 700;
}
.topheadingDona p {
  text-align: center;
  font-size: 22px;
  padding: 0rem 0rem 1.5rem 0rem;
  color: maroon;
}

.donaContent img {
  width: 25rem;
  border-radius: 31% 69% 23% 77% / 66% 18% 82% 34%;
}
.donaContent {
  display: flex;
  align-items: center;
  padding: 2rem;
  flex-direction: row-reverse;
}

.topcontent {
  text-align: justify;
  padding: 2rem 2rem 0.2rem 2rem;
  font-size: 17px;
}
.donacontent {
  text-align: justify;
  padding: 0rem 2rem 0.5rem 2rem;
  font-size: 17px;
}
.btnDona {
  border-radius: 20px;
  background-color: #fd6c11;
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  margin-left: 0rem;
  width: 50%;
}
.btnDona1 {
  border-radius: 20px;
  background-color: #fd6c11;
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  margin-left: 2rem;
}
.donaMiddle {
  display: flex;
  padding: 4rem;
  align-items: center;
}
.donaMiddle img {
  width: 25rem;
  border-radius: 24% 76% 50% 50% / 77% 26% 74% 23% ;
  padding-right: 4rem;
  /*     
    border: 10px solid transparent; /* Set a transparent border to make space for the border image */
  /* border-image-source: url('../images/border.png'); */
  /* border-image-slice: 20 fill; Adjust the slice value and 'fill' keyword as needed */
  /* border-image-repeat: round;  Adjust the repeat property as needed (round, stretch, repeat, etc.) */
}

.donaMiddle ul {
  list-style-image: url(./../images/check-mark-10-16.png);
}
.donaMiddle ul li {
  font-size: 17px;
}

.donaLower h1 {
  font-size: 50px;
  color: #ffd301;
  text-align: center !important;
  padding: 2rem;
}

.donaLower {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.donationdona {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  flex-wrap: wrap;
  
}
.donadona {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  align-items: center;
  margin: 0rem;
  /* margin: 1rem 10rem 1rem 10rem; */
  padding: 1.5rem;
  flex-direction: column;
  margin: 2rem;
  box-shadow: 1px 5px 35px 10px rgba(204, 204, 204, 0.8);
  border-radius: 1rem;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 25%;
}
.donadona:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.nityaseva1 {
  display: flex;
  justify-content: space-around;
}
.nityaseva2 img {
  width: 15rem;
}
.contentdon p {
  font-weight: 900;
  color: #938a8a;
  text-align: center;
  padding: 0.2rem;
}
.nityaseva2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .donaContent {
    flex-direction: column;
    padding: 0rem;
  }
  .donaMiddle {
    flex-direction: column;
    padding: 0rem;
  }
  .donaMiddle ul li {
    text-align: justify;
    padding: 1rem;
  }
  .topheadingDona p {
    text-align: center;
    font-size: 20px;
    padding: 0rem 0.5rem 1.5rem 0.5rem;
  }
  .donaContent img {
    width: 21rem;
  }

  .donaMiddle img {
    width: 21rem;
    /* padding-right: 0rem; */
    padding: 1rem;
  }
  .topcontent,
  .donacontent {
    text-align: justify;
    padding: 2rem 1rem 0.2rem 1rem;
    font-size: 17px;
  }
  .donadona {
    display: flex;
    justify-content: space-evenly;
    /* margin: 0rem; */
    /* margin: 1rem 10rem 1rem 10rem; */
    padding: 0.5rem;
    /* width: calc(53% - 10px); */
    margin-bottom: 20px;
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
  }
  .donaLower h1 {
    font-size: 40px;
    color: #ffd301;
    text-align: center;
    padding: 1rem;
  }
  .matadona h2 {
    text-align: center;
  }
  .nityaseva1 {
    flex-direction: column;
  }
  .contentdon p{
    text-align: center;
  }
}
