.activitydiv{
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.activityheading{
    padding-top: 30px;
    font-weight: 600;
}
.activitycolumn{
    width: 60%;
    display: flex;
    border-radius: 30px 10px 50px 10px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
    margin: 10px;
    cursor: pointer;
}

.activitycolumnimage{
   width: 40%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 30px;
}

.activityimage{
    width: 200px;
    height: 180px;
    border-radius: 30px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
}

.activitycolumndescription{
    width: 70%;
    text-align: left;
    padding: 30px 10px;
}

.activitylink{
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .activitycolumn{
      width: 85%;
      text-align: center;
      flex-direction: column;
    }

    .activitycolumnimage{
        width: 100%;
    }

    .activitycolumndescription{
        width: 100%;
        text-align: center;
        padding: 30px 15px;
    }
  
   
  }