.whatsinsidediv{
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    max-height: 100%;
}

.whatsinsideheading{
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}

.whatsinsiderow{
    display: flex;
    justify-content: space-between;
    width: 85%;
}

.whatsinsidecolumn{
    flex: 1;
    width: 25%;
    border-radius: 10px 10px 0px 0px;
    margin: 15px;
    text-align: center;
    height: 300px;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.whatsinsidecolumn:hover{
    transform: scale(1.1);
  }

.whatsinsidecard{
    border-radius: 10px;
}
.whatsinsideimage{
    height: 200px;
    width: 100%;
    vertical-align: top;
    border-radius: 10px 10px 0px 0px;
}

.whatsinsidepara{
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0px;
}

@media screen and (max-width: 600px) {
    .whatsinsiderow{
      flex-direction: column;
    }
  
    .whatsinsidecolumn{
      width: 100%;
      margin: 15px 15px 15px 0px;
    }
  }