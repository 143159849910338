.homeee{
    width: 100%;
    height:100%;
    overflow-x: hidden;
}

.homeDescription{
    padding:3rem 0rem;
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 0px;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
}

.homeDescription h3{
    font-size: 29px;
    color: #1a1512;
    font-family: 'Montserrat', sans-serif !important;
    line-height: 37px;
    margin: 0;
    font-weight: bold;
    text-align: center;
}

.imageslider{
    height: 70vh;
    overflow: hidden;
    width: 100%;
}

.sliderimage{
    width:100%;
    height:70vh;
    overflow:hidden;
    /* animation: fadeInOut 3s linear infinite */
}

@keyframes fadeInOut {
    0%{
        transform: scale(0.95);
      }
      15%{
        transform: scale(1);
      }
      85%{
        transform: scale(1);
      }
      100%{
        transform: scale(0.95);
      }
}


@media screen and (max-width: 600px) {
    .homeee{
        display: block;
        margin-bottom: 0px;
        overflow-x: hidden !important; 
    }
    .homeimage{
        width:100%;
        height: 35vh;
    }

    .homeDescription{
        padding: 1rem 2rem;
    }

    .homeDescription h3{
        font-size: 15px;
        line-height: 20px;
    }

    .imageslider{
        height: 50vh;
    }

    .sliderimage{
        height: 50vh;
    }
  }

  