.blognextpagediv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.headingdivlist{
    list-style-type: none;
    display: flex;
    flex-direction: row; 
    padding-left: 0px;
}

.blognextpagerow{
    display: flex;
    margin: 30px 0px;
    width: 90%;
    justify-content: space-between;
}

.blognextpagecolumn{
    width: 95%;
}

.blognextpagecolumn1{
    padding-left: 20px;
    width: 30%;
}

.mainimage{
    width: 100%;
}

.middleimage{
    width: 100%;
    height:auto;
}

.headingdivlistitem{
    margin-left: 8px;
    margin-right: 8px;
}

.historyofiskon{
    text-align: center;
}

.historyofiskonimage{
    width: 80%;
}

.contributiondiv1{
    text-align: center;
    border-radius: 10px;
    margin-bottom: 30px;
}

.commentdiv {
    /* Add styling for the comment section */
    margin-bottom: 20px;
}

.comment-label,
.comment-textarea,
.comment-input,
.subscribe-label,
.subscribe-checkbox {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 8px;
    font-size: 16px;
    border-radius: 10px;
}

.comment-submit-btn {
    color: vlack;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    background-color: #e6b745;
}

.subscribe-label {
    margin-bottom: 0; /* Adjust spacing */
}

.input-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.input-column {
    flex: 0 0 48%;
}

@media screen and (max-width:600px){
    .blognextpagerow{
        flex-direction: column;
    }

    .headingdivlistitem{
        margin-right: 0px;
    }

    .middleimage{
        width: 100%;
    }

    .blognextpagecolumn{
        width: 100%;
    }
    .blognextpagecolumn1{
        padding-left: 0px;
        width: 100%;
    }
}
