.getemailupdate-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .getemailupdaterow{
    width: 100%;
    display: flex;
    padding: 10px 90px;
  }
  
  .getemailupdatecolumn {
    width: 45%;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    padding: 20px 50px;
  }

  .frontimage{
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .inputfield{
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin: 10px 0px;
  }

  .textfield{
    width: 50%;
    text-align: left;
  }

  .checkboxes{
    display: flex;
    flex-direction: row;
  }
  
  .inputfields {
    justify-content: flex-start;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .getemaillabel {
    display: flex;
    text-align: left;
    margin-bottom: 5px;
  }
  .emailButton{
    width: 100%;
    background-color: #fd854a;
    font-weight: 700;
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: larger;
  }

  @media screen and (max-width: 600px) {
    .getemailupdaterow{
      padding: 0px;
      flex-direction: column;
    }
    .getemailupdatecolumn{
        width: 100%;
        margin: 0;
        padding: 20px;
    }
    .checkboxes{
      flex-direction: column;
    }
    .getemaillabel{
      text-align: center;
      width: 131%;
    }
  }
  